import jwt_decode from 'jwt-decode';
import { UserToken } from '@/Interfaces/userToken';
import AdminRoleEnum from '@/enum/adminRole';

export const getUserToken = () => {
  const token = localStorage.getItem('token') ?? null;
  if (token) {
    const user: UserToken = jwt_decode(token) as UserToken;
    return user;
  }
  return null;
};

export const saveUserToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const saveRefreshToken = (refreshToken: string) => {
  localStorage.setItem('refresh-token', refreshToken);
};

export const removeToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh-token');
};

export const isSuperAdmin = () => {
  const user = getUserToken();
  return Number(user?.role_id) === AdminRoleEnum.SUPER_ADMIN;
};

export const isAdmin = () => {
  const user = getUserToken();
  return Number(user?.role_id) === AdminRoleEnum.ADMIN;
};

export const isStaff = () => {
  const user = getUserToken();
  return Number(user?.role_id) === AdminRoleEnum.STAFF;
};
