import { Routes as Switch, Route } from 'react-router-dom';
import ProtectedRoute from '@/components/routes/protectedRoute';
import PublicRoute from '@/components/routes/PublicRoute';
import { PATH } from '@/constants/path';
import MainLayout from '@/layout/mainLayout';
import PlainLayout from '@/layout/plainLayout';
import {
  UserManagement,
  LoginPage,
  ProfilePage,
  UserProfileManagement,
  UserFactoryManagement,
  AdminAccountManagement,
  AdminAccountEdit,
  SettingPage,
  UserPackageHistory,
  DashboardPage
} from '@/loadable';

import AdminRoleEnum from '@/enum/adminRole';

// Assign "Pages" to a "/route".
function Routes() {
  return (
    <Switch>
      {/* Protected Routes by role */}
      {/* Admin Routes */}
      <Route
        element={
          <ProtectedRoute
            redirectPath={PATH.HOME}
            roleCheck={[
              // TODO: Check role
              AdminRoleEnum.SUPER_ADMIN,
              AdminRoleEnum.ADMIN
            ]}
          />
        }
      >
        <Route element={<MainLayout />}>
          <Route
            key={PATH.MANAGE_ADMIN}
            path={PATH.MANAGE_ADMIN}
            element={<AdminAccountManagement />}
          />
          <Route
            key={PATH.ADMIN_ACCOUNT}
            path={PATH.ADMIN_ACCOUNT}
            element={<AdminAccountEdit />}
          />
        </Route>
      </Route>

      <Route
        element={
          <ProtectedRoute
            redirectPath={PATH.LOGIN}
            roleCheck={[AdminRoleEnum.SUPER_ADMIN, AdminRoleEnum.ADMIN, AdminRoleEnum.STAFF]}
          />
        }
      >
        <Route element={<MainLayout />}>
          <Route key={PATH.HOME} path={PATH.HOME} element={<DashboardPage />} />
          <Route key={PATH.MANAGE_MEMBER} path={PATH.MANAGE_MEMBER} element={<UserManagement />} />
          <Route
            key="UserOrgProfileManagement"
            path={`${PATH.MANAGE_MEMBER}/:orgId`}
            element={<UserProfileManagement />}
          />
          <Route
            key="UserPackageHistory"
            path={`${PATH.MANAGE_MEMBER}/:orgId/packages`}
            element={<UserPackageHistory />}
          />
          <Route
            key="UserFactoryManagement"
            path={`${PATH.MANAGE_MEMBER}/:orgId/factory/:factoryId`}
            element={<UserFactoryManagement />}
          />
          <Route key={PATH.PROFILE} path={PATH.PROFILE} element={<ProfilePage />} />
          <Route key={PATH.SETTING} path={PATH.SETTING} element={<SettingPage />} />
        </Route>
      </Route>

      {/* Public Routes */}
      <Route element={<PublicRoute redirectPath={PATH.HOME} />}>
        <Route element={<PlainLayout />}>
          <Route key={PATH.LOGIN} path={PATH.LOGIN} element={<LoginPage />} />
        </Route>
      </Route>
    </Switch>
  );
}

export default Routes;
