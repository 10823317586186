export enum PATH {
  HOME = '/',
  MANAGE_ADMIN = '/manage-admin',
  ADMIN_ACCOUNT = '/admin-account',
  MANAGE_MEMBER = '/manage-member',
  MANAGE_MEMBER_PROFILE = '/manage-member',
  PROFILE = '/profile',
  LOGIN = '/login',
  SETTING = '/setting',
  DASHBOARD = '/dashboard'
}

export default PATH;
