import { FC, forwardRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import burgerMenu from '@/assets/svg/hamburger_menu.svg';
import { BsBoxArrowRight, BsGear, BsPerson } from 'react-icons/bs';

import 'react-tooltip/dist/react-tooltip.css';
import { DELAY_HIDE, DELAY_SHOW } from '@/constants/tooltip';

import '@/components/navbar/navbar.scss';
import { getUserToken } from '@/utils/authUtil';
import ConfirmationModal from '@/components/modal/confirmationModal';
import { PATH } from '@/constants/path';
import { useNavigate } from 'react-router-dom';

export interface NavbarProps {
  toggleSidebar: () => void;
  signout: () => void;
}

const Navbar: FC<NavbarProps> = (props: NavbarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = getUserToken();
  const [isShowSignoutModal, toggleModal] = useState<boolean>(false);

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
  };

  const CustomToggle = forwardRef(
    (customToggleProps: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            customToggleProps.onClick(e);
          }}
        >
          {customToggleProps.children}
        </a>
      );
    }
  );

  return (
    <>
      <div className="navbar">
        <div className="container">
          <div className="container__left">
            <div className="hamburger-menu" onClick={() => props.toggleSidebar()}>
              <SVG src={burgerMenu} />
            </div>
            <h3>{t('APPLICATION_TITLE')}</h3>
          </div>

          <div className="container__right pe-3">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle}>
                <button type="button" className="btn-custom px-4 py-3">
                  {user?.name} <KeyboardArrowDownIcon />
                </button>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate(PATH.PROFILE)}>
                  <BsPerson />
                  <span className="ms-2">{t('PROFILE')}</span>
                </Dropdown.Item>

                <Dropdown.Item onClick={() => navigate(PATH.SETTING)}>
                  <BsGear />
                  <span className="ms-2">{t('MANUAL_SETTING')}</span>
                </Dropdown.Item>

                <Dropdown.Item onClick={() => toggleModal(true)}>
                  <BsBoxArrowRight />
                  <span className="ms-2">{t('LOGOUT')}</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <ReactTooltip
            anchorId="tooltip-organization"
            delayShow={DELAY_SHOW}
            delayHide={DELAY_HIDE}
          />
          <ReactTooltip
            anchorId="tooltip-notification"
            delayShow={DELAY_SHOW}
            delayHide={DELAY_HIDE}
          />
        </div>
      </div>

      <ConfirmationModal
        title="ลงชื่อออก"
        description="คุณต้องการลงชื่อออกจากระบบหรือไม่"
        handleClose={() => toggleModal(false)}
        handleSubmit={() => props.signout()}
        show={isShowSignoutModal}
        isLoading={false}
      />
    </>
  );
};

export default Navbar;
