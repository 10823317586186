import { ReactElement, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { PATH } from '@/constants/path';
import { getUserToken } from '@/utils/authUtil';
import _ from 'lodash';

export interface ProtectedRouteInterface {
  redirectPath?: string;
  children?: any;
  roleCheck: number[];
}

function ProtectedRoute({
  redirectPath = PATH.LOGIN,
  children,
  roleCheck
}: ProtectedRouteInterface): ReactElement | null {
  const user = getUserToken();
  const navigate = useNavigate();

  const userRoleId: number = user?.role_id ? Number(user?.role_id) : 0;

  // TODO: uncomment when Role permission complete
  useEffect(() => {
    if (!_.isNull(user) && !_.isUndefined(user)) {
      let isAllow: boolean = false;

      _.each(roleCheck, (role) => {
        const findRole = userRoleId === role;
        if (findRole) {
          isAllow = true;
        }
      });

      if (!isAllow) navigate(redirectPath);
    }
  }, [roleCheck, redirectPath, user?.role_id, navigate]);

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
}

export default ProtectedRoute;
