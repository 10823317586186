import { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './confirmationModal.scss';
import { ButtonVariant } from 'react-bootstrap/esm/types';
import CustomButton from '../customButton/customButton';

interface ConfirmationModalInterface {
  title: string;
  description: string;
  show: boolean;
  isLoading: boolean;
  confirmVariant?: ButtonVariant;
  cancelVariant?: ButtonVariant;
  handleClose: () => void;
  handleSubmit: () => void;
}

const ConfirmationModal: FC<ConfirmationModalInterface> = ({
  title,
  description,
  show,
  handleClose,
  handleSubmit,
  isLoading,
  confirmVariant = 'danger',
  cancelVariant = 'outline-primary'
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={handleClose} className="confirmation-modal">
      <Modal.Body>
        <div className="title">{title}</div>
        <div className="desc">{description}</div>

        <div className="action-button">
          <Button variant={cancelVariant} onClick={handleClose}>
            {t('CANCEL')}
          </Button>
          <CustomButton variant={confirmVariant} onClick={handleSubmit} isLoading={isLoading}>
            {t('CONFIRM')}
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
