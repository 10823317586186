import { createContext, FC, useMemo } from 'react';
import { initializeApp, FirebaseApp, getApps, getApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export interface IFirebaseContext {
  firebase: FirebaseApp;
}

export const FirebaseContext = createContext<IFirebaseContext>({
  firebase: getApps()[0]
});

export interface IFirebaseProviderProps {
  children: any;
}

export const FirebaseProvider: FC<IFirebaseProviderProps> = ({ children }) => {
  const app = useMemo((): IFirebaseContext => {
    if (getApps().length === 0) {
      return { firebase: initializeApp(firebaseConfig) };
    }
    return { firebase: getApp() };
  }, []);

  return <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>;
};
