import { ReactElement } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { PATH } from '@/constants/path';
import { getUserToken } from '@/utils/authUtil';

export interface PublicRouteInterface {
  redirectPath?: string;
  children?: any;
}

function PublicRoute({
  redirectPath = PATH.LOGIN,
  children
}: PublicRouteInterface): ReactElement | null {
  const user = getUserToken();

  if (user) {
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
}

export default PublicRoute;
